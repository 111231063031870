import React, {useState} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import Navbars from "./Navbars";
import Footer from './Footer';
import { Modal, Button } from 'react-bootstrap';


function WebDevelopment() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const handleSubmit = () => {
      // Add your form submission logic here
      // This function is called when the "Add" button is clicked
      // You can replace this with your own logic
      console.log('Form submitted');
    };

  

  return (
    <>
    <Navbars/>
    <body className="defult-home">
    <div className="main-content">
      <div className="full-width-header">
          {/* Breadcrums  */}

          <div class="rs-breadcrumbs img3">
                <div class="breadcrumbs-inner text-center">
                    <h1 class="page-title">Web Development</h1>
                    <ul>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="/">Home</a>
                        </li>
                        <li title="Go To Services">
                            <a class="active" href="/software-development">Services</a>
                        </li>
                        <li>Web Development</li>
                    </ul>
                </div>
            </div>

            {/* Services Single Start  */}

            <div class="rs-services-single pt-120 pb-120 md-pt-80 md-pb-80">
               <div class="container">
                    <div class="row">
                        <div class="col-lg-8 md-mb-10">
                            <div class="services-img">
                                <img src="Images/services/single/1.jpg" alt=""/>
                            </div>
                            <h2 class="mt-34">Responsive Pixel Perfect Design</h2>
                            <p>
                               Econsign soft craft splendid and deliver experiences that shines. We help startups & businesses with creative, intuitive, immersive UI/UX designs that seamlessly guide users in performing the desired actions flawlessly. We help brands create brilliant designs and superior user experiences through UX research, behavioral analytics and market insights. 
                            </p>
                            <div class="brochures" style={{display:"none"}}>
                            <ul class="listing-style">                              
                                <li>
                                    <i class="fa fa-check-circle"></i>
                                    <span>Discover the purpose of the product</span>
                                </li>
								<li>
                                    <i class="fa fa-check-circle"></i>
                                    <span>Define use cases & build storyboards for each use case</span>
                                </li>
								<li>
                                    <i class="fa fa-check-circle"></i>
                                    <span>Wireframing</span>
                                </li>
								<li>
                                    <i class="fa fa-check-circle"></i>
                                    <span>Impressive first impression</span>
                                </li>
                                
                                <li>
                                    <i class="fa fa-check-circle"></i>
                                    <span>Interaction Design</span>
                                </li>
                                <li>
                                    <i class="fa fa-check-circle"></i>
                                    <span>Actual Design output</span>
                                </li>                                
                            </ul>
                            </div>
                           
                            <h3 class="mt-30">24/7 Smart Support</h3>
                            <p class="mt-20 mb-10">
                                We at Econsign Soft are always available for quick smart support 24/7.
                            </p>
                           
                        </div>
                        <div class="col-lg-4 pl-32 md-pl-15">
                            <ul class="services-list">
                                <li><a href="/software-development">Software Development</a></li>
                                <li><a class="active" href="#">Web Development</a></li>
                                <li><a href="/full-stack-development">Full Stack Development</a></li>
                                <li><a href="/app-development">App Development</a></li>
                                <li><a href="/ui-ux-design">UI/UX Design</a></li>
                            </ul>
                            <div class="services-add mb-50 mt-50">
                                <div class="address-item mb-35">
                                    <div class="address-icon">
                                        <i class="fa fa-phone"></i>
                                    </div>
                                </div>
                                <h2 class="title">Have any Questions? <br></br> Call us Today!</h2>
                                <div class="contact">
                                    <a href="tel:02067622438">(+91) 20-67622438</a>
                                </div>
                            </div>
                            <div class="brochures" style={{display:"none"}}>
                                <h3>Brochures</h3>
                                <p>
                                    Cras enim urna, interdum nec por ttitor vitae, sollicitudin eu erosen. Praesent eget mollis nulla sollicitudin.
                                </p>
                                <div class="pdf-btn">
                                    <a class="readon learn-more pdf" href="/contact">Download Now<i class="fa fa-file-pdf-o"></i></a>
                                </div>
                            </div>
                        </div>
                    </div> 
               </div> 
            </div>

            {/* Cta Section  */}

            <div class="rs-cta style1 bg7 pt-80 pb-80">
                <div class="container">
                    <div class="cta-wrap">
                        <div class="row align-items-center">
                            <div class="col-lg-9 col-md-12 md-mb-30">
                                <span>Plan to Start a Project</span>
                                <div class="title-wrap">
                                    <h2 class="epx-title">Our Experts Ready to Help You</h2>
                                </div>
                            </div>
                            <div class="col-lg-3 text-right col-md-12">
                                <div class="button-wrap">
                                    <a class="readon learn-more" onClick={handleShow}>Get In Touch</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Footer  */}
            <Footer/>
            

        <div id="scrollUp" class="orange-color">
            <i class="fa fa-angle-up"></i>
        </div>

        <div class="modal fade search-modal" id="searchModal" tabindex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
            <button type="button" class="close" data-bs-dismiss="modal">
                <span class="flaticon-cross"></span>
            </button>
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="search-block clearfix">
                        <form>
                            <div class="form-group">
                                <input class="form-control" placeholder="Search Here..." type="text"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

          </div>
          </div>
          </body>


          <Modal className="modal-lg" show={show} onHide={handleClose} animation={false} style={{marginLeft:"350px"}}>
        <Modal.Header>
          <Modal.Title>Get In Touch</Modal.Title>
          <div>
            <img
              src="Images/close.png"
              width="25"
              height="25"
              marginTop="14px"
              textAlign="center"
              className="d-inline-block align-top"
              alt="Close"
              style={{ backgroundColor: "white", textAlign: "center", marginLeft: "0px", cursor: "pointer" }}
              onClick={handleClose}
            />
          </div>
        </Modal.Header>
        <Modal.Body >
            <div style={{textAlign:"center"}}>
                <p><b>EmailID</b> :  <span>support@econsignsoft.com</span></p>
                <p><b>WhatsApp Number</b> :  <span>+91-9730104588</span></p>
            </div>
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
          </>
  )
}

export default WebDevelopment
