import React from 'react';

const SkillBar = ({ title, percent }) => {
  const skillBarStyle = {
    width: `${percent}%`,
  };

  return (
    <div className="rs-skillbar style1">
      <div className="cl-skill-bar">
        <span className="skillbar-title">{title}</span>
        <div className="skillbar" data-percent={percent}>
          <div className="skillbar-bar" style={skillBarStyle}></div>
          <span className="skill-bar-percent">{percent}%</span>
        </div>
      </div>
    </div>
  );
};

export default SkillBar;
