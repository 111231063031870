import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import SkillBar from './SkillBar';
import Navbars from "./Navbars";
import Footer from './Footer';

function SoftwareDevelopment() {
  return (
    <>
    <Navbars/>
    <body className="defult-home">
      <div className="main-content">
        <div className="full-width-header">
 {/* Breadcrums  */}


            <div class="rs-breadcrumbs img2">
                <div class="breadcrumbs-inner text-center">
                    <h1 class="page-title">Software Development</h1>
                    <ul>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="/">Home</a>
                        </li>
                        <li title="Go To Services">
                            <a class="active" href="/software-development">Services</a>
                        </li>
                        <li>Software Development</li>
                    </ul>
                </div>
            </div>

            {/* About Section  */}

            <div class="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 pr-40 md-pr-15 md-mb-50">
                            <div class="sec-title4">
                                <span class="sub-text">Software Development</span>
                                <h2 class="title">We Help to Implement Your Ideas into Automation</h2>
                                <div class="heading-line"></div>
                                <div class="desc desc-big">
                                    Over 8 years working in IT services developing software applications and mobile apps for clients all over the world.
                                </div>
                                <div class="desc">
                                   Econsign Soft has a track record of success creating dependable, scalable software solutions that add additional value to your company. Econsign Soft has amassed significant expertise across a wide range of industries, including education, insurance, healthcare, and more, after successfully completing more than 200 software development projects.
                                </div>
                                <div class="btn-part mt-45">
                                    <a class="readon learn-more" href="contact.html">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="software-img">
                                <img src="Images/about/about-us/1.jpg" alt="images"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rs-services style5 pt-120 md-pt-80" style={{height:"300px"}}>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 md-mb-30" style={{height:"300px"}}>
                                <div class="services-item">
                                    <div class="services-icon">
                                       <img src="Images/services/style7/1.png" alt="Images"/> 
                                    </div>
                                    <div class="services-content">
                                        <h3 class="services-title"><a href="/web-development">Expert Peoples</a></h3>
                                        <p class="services-desc">
                                             We have amassed significant expertise across a wide range of industries.	
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 md-mb-30" style={{height:"300px"}}>
                                <div class="services-item">
                                    <div class="services-icon">
                                       <img src="Images/services/style7/2.png" alt="Images"/> 
                                    </div>
                                    <div class="services-content">
                                        <h3 class="services-title"><a href="/web-development">Fast Growing Process</a></h3>
                                        <p class="services-desc">
                                            With our expertise and dedicated approach we help in growing our client business effectively.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4" style={{height:"300px"}}>
                                <div class="services-item">
                                    <div class="services-icon">
                                       <img src="Images/services/style7/3.png" alt="Images"/> 
                                    </div>
                                    <div class="services-content">
                                        <h3 class="services-title"><a href="/web-development">Creative Ideas</a></h3>
                                        <p class="services-desc">
                                            We have innovative and creative businees ideas which helps our client to be one step forward
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>

            <div id="rs-about" class="rs-about gray-color pt-120 pb-120 md-pt-80 md-pb-60">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 pr-45 md-pr-15 md-mb-50">
                            <div class="choose-img">
                               <img src="Images/choose/3.png" alt="images"/>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="sec-title mb-30">
                                <div class="sub-text choose">Why Choose Us</div>
                                <h2 class="title pb-20">
                                    We Are Increasing Business Success With Technology
                                </h2>
                                <div class="desc">
                                   Over 8 years working in IT services developing software applications and mobile apps for clients all over the world.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-10">
                                    <div class="rs-skillbar style1">
                                       <div class="cl-skill-bar">

                                           <SkillBar title="Full Stack Development" percent={92} />
                                            <SkillBar title="Software Development" percent={90} />
                                            <SkillBar title="App Development" percent={85} />
                                            <SkillBar title="Web Development" percent={95} />

                                          <div class="btn-part mt-55">
                                              <a class="readon learn-more" href="contact.html">Learn-More</a>
                                          </div>
                                      </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer  */}
           <Footer/> 
           


        <div id="scrollUp" class="orange-color">
            <i class="fa fa-angle-up"></i>
        </div>

        <div class="modal fade search-modal" id="searchModal" tabindex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
            <button type="button" class="close" data-bs-dismiss="modal">
                <span class="flaticon-cross"></span>
            </button>
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="search-block clearfix">
                        <form>
                            <div class="form-group">
                                <input class="form-control" placeholder="Search Here..." type="text"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

            </div>
            </div>
            </body>
            </>
  )
}

export default SoftwareDevelopment
