import React ,{ useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Navbars from "./Navbars";
import Footer from './Footer';


function About() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      });
      const [formErrors, setFormErrors] = useState({});
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
    
        // Basic validation: Check if required fields are not empty
        const errors = {};
        if (!formData.name) {
          errors.name = 'name is required';
        }
        if (!formData.email) {
          errors.email = 'email is required';
        }
        if (!formData.phone) {
          errors.phone = 'phone number is required';
        }
        if (!formData.subject) {
          errors.subject = 'website is required';
        }
        if (!formData.message) {
            errors.message = 'message is required';
          }
    
        if (Object.keys(errors).length === 0) {
          // Form is valid, you can submit it or perform further actions here
          console.log('Form submitted:', formData);
        } else {
          setFormErrors(errors);
        }
    }
  return (
    <>
    <Navbars/>
    <body className="defult-home">
      <div className="main-content">
        <div className="full-width-header">
          <header id="rs-header" className="rs-header">
            {/* Menu Start  */}

     {/* Breadcrumbs Start */}

                    <div class="rs-breadcrumbs img3">
                <div class="breadcrumbs-inner text-center">
                    <h1 class="page-title">About</h1>
                    <ul>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="/">Home</a>
                        </li>
                        <li>About</li>
                    </ul>
                </div>
            </div>

            {/* About Section  */}

            <div class="rs-about gray-color pt-120 pb-120 md-pt-80 md-pb-80">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 md-mb-30">
                            <div class="rs-animation-shape">
                                <div class="images">
                                   <img src="Images/about/about-3.png" alt=""/> 
                                </div>
                                <div class="middle-image2">
                                   <img class="dance3" src="Images/about/effect-1.png" alt=""/> 
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 pl-60 md-pl-15">
                            <div class="contact-wrap">
                                <div class="sec-title mb-30">
                                    <div class="sub-text style-bg">About Us</div>
                                    <h2 class="title pb-38">
                                        We Are Increasing Business Success With Technology
                                    </h2>
                                    <div class="desc pb-35">
                                       Over 8 years working in IT services developing web applications, software applications and mobile apps for clients all over the world.
                                    </div>
                                    <p class="margin-0 pb-15">
                                      EconSign Soft is a global information technology and consulting company have a well-established presence in India. We ensure the highest levels of certainty and satisfaction through a deep-set commitment to clients with our technology expertise, comprehensive portfolio of services and vertically aligned business model. We are committed to providing innovative web and mobile application development services to clients that not only create tremendous value, but provide an impressive return on investment.
                                    </p>
                                </div>
                                <div class="btn-part">
                                    <a class="readon learn-more" href="contact.html">Learn-More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="shape-image">
                        <img class="top dance" src="Images/about/dotted-3.png" alt=""/>
                    </div>
                </div>
            </div>

            {/* Team Section Start  */}


            <div class="rs-team pt-120 pb-120 md-pt-80 md-pb-80 xs-pb-54" style={{display:"none"}}> 
                <div class="sec-title2 text-center mb-30">
                    <span class="sub-text style-bg white-color">Team</span>
                    <h2 class="title white-color">
                        Expert IT Consultants
                    </h2>
                </div>               
                <div class="container">
                    <div class="container"> 
                        <div class="rs-carousel owl-carousel" data-loop="true" data-items="3" data-margin="30" data-autoplay="true" data-hoverpause="true" data-autoplay-timeout="5000" data-smart-speed="800" data-dots="false" data-nav="false" data-nav-speed="false" data-center-mode="false" data-mobile-device="1" data-mobile-device-nav="false" data-mobile-device-dots="true" data-ipad-device="2" data-ipad-device-nav="false" data-ipad-device-dots="true" data-ipad-device2="2" data-ipad-device-nav2="false" data-ipad-device-dots2="true" data-md-device="3" data-md-device-nav="false" data-md-device-dots="true">
                            <div class="team-item-wrap">
                                <div class="team-wrap">
                                    <div class="image-inner">
                                        <a href="single-team.html"><img src="Images/team/style1/1.jpg" alt=""/></a>
                                    </div>
                                </div>
                                <div class="team-content text-center">
                                    <h4 class="person-name"><a href="single-team.html">Makhaia Antitni</a></h4>
                                    <span class="designation">President & CEO</span>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-item-wrap">
                                <div class="team-wrap">
                                    <div class="image-inner">
                                        <a href="single-team.html"><img src="Images/team/style1/2.jpg" alt=""/></a>
                                    </div>
                                </div>
                                <div class="team-content text-center">
                                    <h4 class="person-name"><a href="single-team.html">Corey Anderson</a></h4>
                                    <span class="designation">CEO & Founder</span>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-item-wrap">
                                <div class="team-wrap">
                                    <div class="image-inner">
                                        <a href="single-team.html"><img src="Images/team/style1/3.jpg" alt=""/></a>
                                    </div>
                                </div>
                                <div class="team-content text-center">
                                    <h4 class="person-name"><a href="single-team.html">Masud Rana</a></h4>
                                    <span class="designation">Web Developer</span>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-item-wrap">
                                <div class="team-wrap">
                                    <div class="image-inner">
                                        <a href="single-team.html"><img src="Images/team/style1/4.jpg" alt=""/></a>
                                    </div>
                                </div>
                                <div class="team-content text-center">
                                    <h4 class="person-name"><a href="single-team.html">Najmul Huda</a></h4>
                                    <span class="designation">Digital Marketer</span>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-item-wrap">
                                <div class="team-wrap">
                                    <div class="image-inner">
                                        <a href="single-team.html"><img src="Images/team/style1/5.jpg" alt=""/></a>
                                    </div>
                                </div>
                                <div class="team-content text-center">
                                    <h4 class="person-name"><a href="single-team.html">Rushali Rumi</a></h4>
                                    <span class="designation">Design Lead</span>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-item-wrap">
                                <div class="team-wrap">
                                    <div class="image-inner">
                                        <a href="single-team.html"><img src="Images/team/style1/6.jpg" alt=""/></a>
                                    </div>
                                </div>
                                <div class="team-content text-center">
                                    <h4 class="person-name"><a href="single-team.html">Abu Sayed</a></h4>
                                    <span class="designation">App Developer</span>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-item-wrap">
                                <div class="team-wrap">
                                    <div class="image-inner">
                                        <a href="single-team.html"><img src="Images/team/style1/7.jpg" alt=""/></a>
                                    </div>
                                </div>
                                <div class="team-content text-center">
                                    <h4 class="person-name"><a href="single-team.html">Sonia Akhter</a></h4>
                                    <span class="designation">Graphic Artist</span>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-item-wrap">
                                <div class="team-wrap">
                                    <div class="image-inner">
                                        <a href="single-team.html"><img src="Images/team/style1/8.jpg" alt=""/></a>
                                    </div>
                                </div>
                                <div class="team-content text-center">
                                    <h4 class="person-name"><a href="single-team.html">Rayhan Ali</a></h4>
                                    <span class="designation">CEO & Founder</span>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-item-wrap">
                                <div class="team-wrap">
                                    <div class="image-inner">
                                        <a href="single-team.html"><img src="Images/team/style1/9.jpg" alt=""/></a>
                                    </div>
                                </div>
                                <div class="team-content text-center">
                                    <h4 class="person-name"><a href="single-team.html">Benjir Akther</a></h4>
                                    <span class="designation">Graphic Designer</span>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-item-wrap">
                                <div class="team-wrap">
                                    <div class="image-inner">
                                        <a href="single-team.html"><img src="Images/team/style1/1.jpg" alt=""/></a>
                                    </div>
                                </div>
                                <div class="team-content text-center">
                                    <h4 class="person-name"><a href="single-team.html">Makhaia Antitni</a></h4>
                                    <span class="designation">President & CEO</span>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-item-wrap">
                                <div class="team-wrap">
                                    <div class="image-inner">
                                        <a href="single-team.html"><img src="Images/team/style1/2.jpg" alt=""/></a>
                                    </div>
                                </div>
                                <div class="team-content text-center">
                                    <h4 class="person-name"><a href="single-team.html">Corey Anderson</a></h4>
                                    <span class="designation">CEO & Founder</span>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-item-wrap">
                                <div class="team-wrap">
                                    <div class="image-inner">
                                        <a href="single-team.html"><img src="Images/team/style1/3.jpg" alt=""/></a>
                                    </div>
                                </div>
                                <div class="team-content text-center">
                                    <h4 class="person-name"><a href="single-team.html">Masud Rana</a></h4>
                                    <span class="designation">Web Developer</span>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-item-wrap">
                                <div class="team-wrap">
                                    <div class="image-inner">
                                        <a href="single-team.html"><img src="Images/team/style1/4.jpg" alt=""/></a>
                                    </div>
                                </div>
                                <div class="team-content text-center">
                                    <h4 class="person-name"><a href="single-team.html">Najmul Huda</a></h4>
                                    <span class="designation">Digital Marketer</span>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

            {/* Process Section  */}

            <div class="rs-process style2 pt-120 pb-120 md-pt-80 md-pb-73">
                <div class="container">
                    <div class="sec-title2 text-center mb-45">
                        <span class="sub-text style-bg">Process</span>
                        <h2 class="title title2">
                           Our Working Process
                        </h2>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-sm-6 md-mb-50">
                            <div class="addon-process">
                                <div class="process-wrap">
                                    <div class="process-img">
                                        <img src="Images/process/1.png" alt=""/>
                                    </div>
                                    <div class="process-text">
                                        <h3 class="title">Discovery</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 md-mb-50">
                            <div class="addon-process">
                                <div class="process-wrap">
                                    <div class="process-img">
                                        <img src="Images/process/2.png" alt=""/>
                                    </div>
                                    <div class="process-text">
                                        <h3 class="title"> Planning</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="addon-process">
                                <div class="process-wrap">
                                    <div class="process-img">
                                        <img src="Images/process/3.png" alt=""/>
                                    </div>
                                    <div class="process-text">
                                        <h3 class="title">Execute</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="addon-process">
                                <div class="process-wrap">
                                    <div class="process-img">
                                        <img src="Images/process/4.png" alt=""/>
                                    </div>
                                    <div class="process-text">
                                        <h3 class="title">Deliver</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        {/* Pricing Section  */}

        <div class="rs-pricing style2 gray-color pt-120 pb-143 md-pt-80 md-pb-80"  style={{display:"none"}}>
                <div class="container">
                    <div class="sec-title2 text-center mb-45">
                        <span class="sub-text style-bg">Pricing</span>
                        <h2 class="title title2">
                           Our Pricing Plan
                        </h2>
                    </div>
                   <div class="row">
                       <div class="col-lg-4 md-mb-30">
                           <div class="pricing-table new-style2">
                               <div class="pricing-badge">
                                   Silver
                               </div>
                               <div class="pricing-icon">
                                   <img src="Images/pricing/main-home/icons/1.png" alt=""/>
                               </div>
                               <div class="pricing-table-price">
                                    <div class="pricing-table-bags">
                                        <span class="pricing-currency">$</span>
                                        <span class="table-price-text">29.99</span>
                                        <span class="table-period">Monthly Package</span>
                                    </div>
                               </div>
                               <div class="pricing-table-body">
                                   <ul>
                                       <li><i class="fa fa-check"></i><span>Powerful Admin Panel</span></li>
                                       <li><i class="fa fa-check"></i><span>1 Native Android App</span></li>
                                       <li><i class="fa fa-close"></i><span>Multi-Language Support</span></li>
                                       <li><i class="fa fa-check"></i><span>Support via E-mail and Phone</span></li>
                                   </ul>
                               </div>
                               <div class="btn-part">
                                   <a class="readon buy-now" href="contact.html">Buy Now</a>
                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 md-mb-30">
                           <div class="pricing-table primary-bg">
                               <div class="pricing-badge white-color-bg">
                                   Gold
                               </div>
                               <div class="pricing-icon">
                                   <img src="Images/pricing/main-home/icons/2.png" alt=""/>
                               </div>
                              <div class="pricing-table-price">
                                   <div class="pricing-table-bags">
                                       <span class="pricing-currency">$</span>
                                       <span class="table-price-text">39.99</span>
                                       <span class="table-period">Monthly Package</span>
                                   </div>
                              </div>
                               <div class="pricing-table-body">
                                   <ul>
                                        <li><i class="fa fa-check"></i><span>Powerful Admin Panel</span></li>
                                        <li><i class="fa fa-check"></i><span>2 Native Android App</span></li>
                                        <li><i class="fa fa-check"></i><span>Multi-Language Support</span></li>
                                        <li><i class="fa fa-check"></i><span>Support via E-mail and Phone</span></li>
                                   </ul>
                               </div>
                               <div class="btn-part">
                                   <a class="readon buy-now" href="contact.html">Buy Now</a>
                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4">
                           <div class="pricing-table new-style2">
                               <div class="pricing-badge">
                                   Platinum
                               </div>
                               <div class="pricing-icon">
                                   <img src="Images/pricing/main-home/icons/3.png" alt=""/>
                               </div>
                                <div class="pricing-table-price">
                                     <div class="pricing-table-bags">
                                         <span class="pricing-currency">$</span>
                                         <span class="table-price-text">79.99</span>
                                         <span class="table-period">Monthly Package</span>
                                     </div>
                                </div>
                               <div class="pricing-table-body">
                                   <ul>
                                        <li><i class="fa fa-check"></i><span>Powerful Admin Panel</span></li>
                                        <li><i class="fa fa-check"></i><span>3 Native Android App</span></li>
                                        <li><i class="fa fa-check"></i><span>Multi-Language Support</span></li>
                                        <li><i class="fa fa-check"></i><span>Support via E-mail and Phone</span></li>
                                   </ul>
                               </div>
                               <div class="btn-part">
                                   <a class="readon buy-now" href="contact.html">Buy Now</a>
                               </div>
                           </div>
                       </div>
                   </div>
                </div>
            </div>

            {/* Counter Section  */}

            <div class="rs-contact-wrap bg5 pt-120 pb-390 md-pt-80">
               <div class="container">
                 <div class="sec-title2 text-center mb-30">
                     <span class="sub-text style-bg white-color">Contact</span>
                     <h2 class="title white-color">
                        Request A Free Consultation
                     </h2>
                 </div>           
               </div>
            </div>

            {/* Video Section  */}

            <div class="rs-video-wrap style2 inner pb-120 md-pb-80">
                <div class="container">
                    <div class="row margin-0 gray-color">
                        <div class="col-lg-6 padding-0">
                            <div class="video-item">
                                <div class="rs-videos">
                                    <div class="animate-border main-home style2">
                                        <a class="popup-border popup-videos" href="https://www.youtube.com/watch?v=YLN1Argi7ik">
                                            <i class="fa fa-play"></i>
                                        </a>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div class="col-lg-6 padding-0">
                            <div class="rs-requset">                              
                                <div id="form-messages"></div>
                                <form id="contact-form" method="post" action="mailer.php">
                                    <fieldset>
                                        <div class="row">
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="name" name="name" placeholder="Name" required=""/>
                                                {formErrors.name && <p class="error-message" style={{ color: 'red' }}>{formErrors.name}</p>}
                                            </div> 
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="email" name="email" placeholder="E-Mail" required=""/>
                                                {formErrors.email && <p class="error-message" style={{ color: 'red' }}>{formErrors.email}</p>}
                                            </div>   
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="phone" name="phone" placeholder="Phone Number" required=""/>
                                                {formErrors.phone && <p class="error-message" style={{ color: 'red' }}>{formErrors.phone}</p>}
                                            </div>   
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="Website" name="subject" placeholder="Your Website" required=""/>
                                                {formErrors.subject && <p class="error-message" style={{ color: 'red' }}>{formErrors.subject}</p>}
                                            </div>
                                      
                                            <div class="col-lg-12 mb-45">
                                                <textarea class="from-control" id="message" name="message" placeholder="Your message Here" required=""></textarea>
                                                {formErrors.message && <p class="error-message" style={{ color: 'red' }}>{formErrors.message}</p>}
                                            </div>
                                        </div>
                                        <div class="btn-part">
                                          <a class="submit sub-small" href="#" onClick={handleSubmit}>Submit Now</a>
                                        </div> 
                                    </fieldset>
                                </form> 
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

            {/* Footer  */}
       <Footer/>

    {/* start scrollUp  */}

        <div id="scrollUp" class="orange-color">
            <i class="fa fa-angle-up"></i>
        </div>

    {/* Search Modal Start  */}

        <div class="modal fade search-modal" id="searchModal" tabindex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
            <button type="button" class="close" data-bs-dismiss="modal">
                <span class="flaticon-cross"></span>
            </button>
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="search-block clearfix">
                        <form>
                            <div class="form-group">
                                <input class="form-control" placeholder="Search Here..." type="text"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
                </header>
            </div>
            </div>
            </body>
      
    </>
  )
}

export default About
