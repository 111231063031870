import React ,{ useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

function Footer() {
const navigate = useNavigate();
const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const [shows, setShows] = useState(false);    
const handleShow = () => {
    setShow(true);
    }



  return (
    <>
    {/* Footer Start  */}

    <footer id="rs-footer" class="rs-footer">
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-12 col-sm-12 footer-widget">
                            <div class="footer-logo mb-30">
                                <a href="/"><img src="/Images/elogo.png" alt=""/></a>
                            </div>
                              <div class="textwidget pb-30"><p>EconSign Soft is a software development company with a full cycle of services. For over 8 years, we have been helping enterprises around the world to transform business by creating effective digital solutions with the use of innovative technologies.</p>
                              </div>
                              <ul class="footer-social md-mb-30">  
                                  <li> 
                                      <a href="https://www.facebook.com/econsignsoft" target="_blank"><span><i class="fa fa-facebook"></i></span></a> 
                                  </li>
                                  <li> 
                                      <a href="https://twitter.com/econsignsoft" target="_blank"><span><i class="fa fa-twitter"></i></span></a> 
                                  </li>

                                  <li> 
                                      <a href="https://www.linkedin.com/company/econsignsoft" target="_blank"><span><i class="fa fa-linkedin"></i></span></a> 
                                  </li>
                                  <li> 
                                      <a href="https://www.instagram.com/_econsignsoft_/?igshid=YmMyMTA2M2Y%3D" target="_blank"><span><i class="fa fa-instagram"></i></span></a> 
                                  </li>
                                                                           
                              </ul>
                        </div>
                        <div class="col-lg-3 col-md-12 col-sm-12 pl-45 md-pl-15 md-mb-30">
                            <h3 class="widget-title"style={{marginLeft:"10px"}}>IT Services</h3>
                            <ul class="site-map">
                                 <li><a href="/software-development">Software Development</a></li>
                                <li><a href="/web-development">Web Development</a></li>
                                <li><a href="/full-stack-development">Full Stack Development</a></li>
                                <li><a href="/app-development">App Development</a></li>
                                <li><a href="/ui-ux-design">UI/UX Design</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-3 col-md-12 col-sm-12 md-mb-30">
                            <h3 class="widget-title"style={{marginLeft:"10px"}}>Contact Info</h3>
                            <ul class="address-widget">
                                <li>
                                <i class="flaticon-location" onClick={() => handleShow()}></i>
                                    <div class="desc">F3, Sai Shraddha Society, Kondhwa, Pune India</div>
                                </li>
                                <li>
                                    <i class="flaticon-call"></i>
                                    <div class="desc">
                                       <a href="tel:(+91) 20-67622438">(+91) 20-67622438</a>
                                    </div>
                                </li>
                                <li>
                                    <i class="flaticon-email"></i>
                                    <div class="desc">
                                        <a href="mailto:support@econsignsoft.com">support@econsignsoft.com</a>
                                    </div>
                                </li>
                                <li>
                                    <i class="flaticon-clock-1"></i>
                                    <div class="desc">
                                        Opening Hours: 09:30 - 18:30   
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-3 col-md-12 col-sm-12">
                            <h3 class="widget-title" style={{marginLeft:"1px"}}>News letter</h3>
                            <p class="widget-desc">We denounce with righteous and in and dislike men who are so beguiled and demo realized.</p>
                            <p>
                                <input type="email" name="EMAIL" placeholder="Your email address" required=""/>
                                <em class="paper-plane"><input type="submit" value="Sign up"/><span href="/https://mail.google.com/mail/u/0/#inbox"></span></em>
                                <i class="flaticon-send"></i>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">                    
                    <div class="row y-middle">
                        <div class="col-lg-6 text-right md-mb-10 order-last">
                            <ul class="copy-right-menu">
                                <li><a href="/">Home</a></li>
                                <li><a href="/about">About</a></li>
                                <li><a href="/software-development">Services</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-6">
                            <div class="copyright">
                                <p>&copy; Copyright 2023 Econsign Soft. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </>    
  );
}

export default Footer;
