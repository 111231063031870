import {React, useEffect} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import '../assets/js/bundle.js?ver=3.1.3';
// import '../assets/js/scripts.js?ver=3.1.3';


function Navbars() {

    const navigate = useNavigate();
    const AdminName=localStorage.getItem('fullName');
    
    // useEffect(() => {
    //     if(AdminName == null){
    //         console.log("Checklogin");
    //         navigate('/');
    //     }
    // });

    function handleLogoutClick() {
        localStorage.clear();        
        navigate('/');
      }

      function NavigateChangePass() {
   
        navigate('/change-password');
      }
      


  return (
    <>       
            {/* <div class="nk-header nk-header-fluid is-theme">
                <div class="container-xxl wide-xxl">
                    <div class="nk-header-wrap"> */}


        {/* <body class="defult-home">              */}
        <div class="main-content">
            
            <div class="full-width-header">
                <header id="rs-header" class="rs-header">
                    <div class="topbar-area">
                        <div class="container">
                            <div class="row rs-vertical-middle">
                                <div class="col-lg-3">
                                    <div class="logo-part">
                                        <a href="index.html"><img src="Images/eLogo.png" alt=""/></a>
                                    </div>
                                </div>
                                <div class="col-lg-9 text-right">
                                    <ul class="rs-contact-info">
                                        <li class="contact-part">
                                            <i class="flaticon-location"></i>
                                            <span class="contact-info">
                                                <span>Address</span>
                                                Kondhwa Pune
                                            </span>
                                        </li>
                                        <li class="contact-part">
                                            <i class="flaticon-email"></i>
                                            <span class="contact-info">
                                                <span>E-mail</span>
                                                <a href="#"> info@econsignsoft.com</a>
                                            </span>
                                        </li>
                                        <li class="contact-part no-border">
                                             <i class="flaticon-call"></i>
                                            <span class="contact-info">
                                                <span>Phone</span>
                                                 +91 20-67622438
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Main Content End  */}

                    {/* Menu Start  */}

                    <div class="menu-area menu-sticky">
                        <div class="container">
                            <div class="logo-area">
                                <a href="/">
                                    <img class="sticky-logo" src="Images/eLogo.png" alt="logo"/>
                                </a>
                            </div>
                            <div class="rs-menu-area">
                                <div class="main-menu">
                                    <div class="mobile-menu">
                                        <a href="/" class="mobile-logo">
                                            <img src="Images/eLogo.png" alt="logo"/>
                                        </a>
                                        <a href="#" class="rs-menu-toggle rs-menu-toggle-close">
                                            <i class="fa fa-bars"></i>
                                        </a>
                                    </div>
                                    <nav class="rs-menu">
                                        <ul class="nav-menu">
                                            <li class="rs-mega-menu"><a href="/">Home</a></li>
                                            <li>
                                                <a href="/about">About</a>
                                            </li>
                                              <li class="menu-item-has-children">
                                                <a href="#">Services</a>
                                               <ul class="sub-menu">
                                                    <li><a href="/software-development">Software Development</a></li>
													<li><a href="/web-development">Web Development</a></li>
													<li><a href="/full-stack-development">Full Stack Development</a></li>
													<li><a href="/app-development">App Development</a></li>
													<li><a href="/ui-ux-design">UI/UX Design</a></li>
                                                </ul>
                                            </li>
                                            {/* <li class="menu-item-has-children">
                                                <a href="#">Industries</a>
                                                 <ul class="sub-menu">
                                                    <li><a href="#">Education</a> </li>
                                                    <li><a href="#">Insurance</a> </li>
                                                    <li><a href="#">Ecommerce</a> </li>
                                                    <li><a href="#">Healthcare</a></li>
                                                </ul>
                                            </li> */}
                                            <li>
                                                <a href="/contact">Contact</a>
                                            </li>
                                        </ul> 
                                    </nav>                                        
                                </div>                                
                            </div>
                            <div class="expand-btn-inner search-icon hidden-sticky hidden-md">
                                <ul style={{display:"none"}}>
                                    <li class="sidebarmenu-search">
                                        <a class="hidden-xs rs-search" data-bs-toggle="modal" data-bs-target="#searchModal" href="#">
                                            <i class="flaticon-search"></i>
                                        </a>
                                    </li>
                                </ul>
                                <div class="toolbar-sl-share">
                                    <ul class="social">
                                        <li><a href="https://www.facebook.com/econsignsoft"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="https://twitter.com/econsignsoft"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/econsignsoft"><i class="fa fa-linkedin"></i></a></li>
                                        <li><a href="https://www.instagram.com/_econsignsoft_/?igshid=YmMyMTA2M2Y%3D"><i class="fa fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>

                </div>
                    {/* </div>
                </div>
            </div>
             */}
             {/* </body> */}
    </>
  );
}

export default Navbars;