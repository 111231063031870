import React, { useEffect } from 'react';
// import { Helmet } from 'react-helmet';
// import Navbar from './Components/Navbar';
import './assets/css/responsive.css';
import './assets/css/style.css';
import './assets/css/rs-spacing.css';
import './assets/css/rsmenu-main.css';
import './assets/css/magnific-popup.css';
import './assets/css/off-canvas.css';
import './assets/css/slick.css';
import './assets/css/animate.css';
import './assets/fonts/flaticon.css';
import './assets/css/font-awesome.min.css';
import './assets/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Index from './Components/Index';
import './assets/css/bootstrap.min.css';
import About from './Components/About';
import SoftwareDevelopment from './Components/SoftwareDevelopment';
import WebDevelopment from './Components/WebDevelopment';
import FullStackDevelopment from './Components/FullStackDevelopment';
import AppDevelopment from './Components/AppDevelopment';
import UIUXDesign from './Components/UIUXDesign';
import Contact from './Components/Contact';
import Navbars from './Components/Navbars';
import Footer from './Components/Footer';
// import './assets/js/skill.bars.jquery';

function App() {
  useEffect(() => {
    // You can use Modernizr here or perform any required operations
    // that depend on the modernizr-2.8.3.min.js file.
    // Example: Modernizr.yourMethod();
  }, []);

  return (
    <div className="nk-app-root">
{/* {/ <Helmet> /} */}
    <script src="https://oss.maxcdn.com/html5shiv/3.7.2/html5shiv.min.js"></script>
    <script src="https://oss.maxcdn.com/respond/1.4.2/respond.min.js"></script>
    {/* <script src="assets/js/skill.bars.jquery.js"></script>  */}
    

    {/* {/ </Helmet> /} */}
        
      <div className="nk-wrap">
        <Router>
          <Routes>
            
            <Route exact path="/" element={<Index />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/software-development" element={<SoftwareDevelopment />} />
            <Route exact path="/web-development" element={<WebDevelopment />} />
            <Route exact path="/full-stack-development" element={<FullStackDevelopment />} />
            <Route exact path="/app-development" element={<AppDevelopment />} />
            <Route exact path="/ui-ux-design" element={<UIUXDesign />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/navbars" element={<Navbars />} />
            <Route exact path="/footer" element={<Footer />} />
          

          </Routes>
        </Router>
      </div>

      <script src="assets/js/jquery.counterup.min.js"></script>
      <script src="assets/js/waypoints.min.js"></script>
      <script src="assets/js/swiper.min.js"></script>
      <script src="assets/js/particles.min.js"></script>
      <script src="assets/js/main.js"></script>
      <script src="assets/js/contact.form.js"></script>
      <script src="assets/js/pointer.js"></script>
      <script src="assets/js/plugins.js"></script>
      <script src="assets/js/jquery.magnific-popup.min.js"></script>
      <script src="assets/js/wow.min.js"></script>
      <script src="assets/js/modernizr-2.8.3.min.js"></script>
      <script src="assets/js/bootstrap.min.js"></script>
      <script src="assets/js/rsmenu-main.js"></script>
      <script src="assets/js/jquery.nav.js"></script>
      <script src="assets/js/owl.carousel.min.js"></script>
      <script src="assets/js/skill.bars.jquery.js"></script>
      <script src="assets/js/jquery.min.js"></script>
    </div>
  );
}

export default App;
