import React ,{ useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Navbars from "./Navbars";
import Footer from './Footer';


function Contact() {


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      });
      const [formErrors, setFormErrors] = useState({});
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
    
        // Basic validation: Check if required fields are not empty
        const errors = {};
        if (!formData.name) {
          errors.name = 'name is required';
        }
        if (!formData.email) {
          errors.email = 'email is required';
        }
        if (!formData.phone) {
          errors.phone = 'phone number is required';
        }
        if (!formData.subject) {
          errors.subject = 'website is required';
        }
        if (!formData.message) {
            errors.message = 'message is required';
          }
    
        if (Object.keys(errors).length === 0) {
          // Form is valid, you can submit it or perform further actions here
          console.log('Form submitted:', formData);
        } else {
          setFormErrors(errors);
        }


}
  return (
    <>
    <Navbars/>
    <body class="defult-home">
        
        {/* <div id="loader" class="loader">
            <div class="loader-container"></div>
        </div> */}
     
        <div class="main-content">
            {/* Breadcrums  */}

            <div class="rs-breadcrumbs img3">
                <div class="breadcrumbs-inner text-center">
                    <h1 class="page-title">Contact</h1>
                    <ul>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="/">Home</a>
                        </li>
                       <li>Contact</li>
                    </ul>
                </div>
            </div>

            {/* Contact Section  */}

            <div class="rs-contact pt-120 md-pt-80">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 md-mb-60">
                           <div class="contact-box">
                                <div class="sec-title mb-45">
                                    <span class="sub-text new-text white-color">Let's Talk</span>
                                    <h2 class="title white-color">Speak With Expert Engineers.</h2>
                                </div>
                               <div class="address-box mb-25">
                                   <div class="address-icon">
                                       <i class="fa fa-home"></i>
                                   </div>
                                   <div class="address-text">
                                        <span class="label">Email:</span>
                                        <a href="mailto:support@econsignsoft.com">support@econsignsoft.com</a>
                                   </div>
                               </div>
                               <div class="address-box mb-25">
                                   <div class="address-icon">
                                       <i class="fa fa-phone"></i>
                                   </div>
                                   <div class="address-text">
                                       <span class="label">Phone:</span>
                                       <a href="tel:02067622438">+91 20-67622438</a>
                                   </div>
                               </div>
                               <div class="address-box">
                                   <div class="address-icon">
                                       <i class="fa fa-map-marker"></i>
                                   </div>
                                   <div class="address-text">
                                       <span class="label">Address:</span>
                                       <div class="desc">Kondhwa, Pune-411060 India</div>
                                   </div>
                               </div>
                           </div>
                        </div> 
                        <div class="col-lg-8 pl-70 md-pl-15">
                            <div class="contact-widget">
                               <div class="sec-title2 mb-40">
                                   <span class="sub-text contact mb-15">Get In Touch</span>
                                   <h2 class="title testi-title">Fill The Form Below</h2>

                               </div>
                                <div id="form-messages"></div>
                                <form id="contact-form" method="post" action="mailer.php">
                                    <fieldset>
                                        <div class="row">
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="name" name="name" placeholder="Name" required=""/>
                                                {formErrors.name && <p class="error-message" style={{ color: 'red' }}>{formErrors.name}</p>}
                                            </div> 
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="email" name="email" placeholder="E-Mail" required=""/>
                                                {formErrors.email && <p class="error-message" style={{ color: 'red' }}>{formErrors.email}</p>}
                                            </div>  
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="phone" name="phone" placeholder="Phone Number" required=""/>
                                                {formErrors.phone && <p class="error-message" style={{ color: 'red' }}>{formErrors.phone}</p>}
                                            </div>                                            
                                            <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="Website" name="subject" placeholder="Your Website" required=""/>
                                                {formErrors.subject && <p class="error-message" style={{ color: 'red' }}>{formErrors.subject}</p>}
                                            </div>                                      
                                            <div class="col-lg-12 mb-30">
                                                <textarea class="from-control" id="message" name="message" placeholder="Your message Here" required=""></textarea>
                                                {formErrors.message && <p class="error-message" style={{ color: 'red' }}>{formErrors.message}</p>}
                                            </div>      
                                        </div>
                                        <div class="btn-part">                                            
                                            <div class="form-group mb-0">
                                                <input class="readon learn-more submit" type="submit" value="Submit Now" onClick={handleSubmit}/>
                                            </div>
                                        </div> 
                                    </fieldset>
                                </form> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="map-canvas pt-120 md-pt-80">
                   <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15139.171147451823!2d73.8993912!3d18.4477151!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ebd31be5f9c7%3A0xb407ae10706907e7!2sEconsign%20Soft%20Private%20Limited!5e0!3m2!1sen!2sin!4v1674721113230!5m2!1sen!2sin" style={{width:"600",height:"450",border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div> 
            </div>

        </div> 

        {/* Footer  */}
         <Footer/>

            {/* Last  */}

            <div id="scrollUp" class="orange-color">
            <i class="fa fa-angle-up"></i>
        </div>

        <div class="modal fade search-modal" id="searchModal" tabindex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
            <button type="button" class="close" data-bs-dismiss="modal">
                <span class="flaticon-cross"></span>
            </button>
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="search-block clearfix">
                        <form>
                            <div class="form-group">
                                <input class="form-control" placeholder="Search Here..." type="text"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
        </body>
        </>
  )
}

export default Contact
