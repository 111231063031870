import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import SkillBar from './SkillBar';
import Navbars from "./Navbars";
import Footer from './Footer';


function UIUXDesign() {
  return (
    <>
     {/* <body class="defult-home"> */}

        {/* <div id="loader" class="loader">
            <div class="loader-container"></div>
        </div> */}
 
        {/* Main Content  */}
        <Navbars/>
        {/* <div class="main-content"> */}
            
            

            {/* Breadcrumbs Start  */}

            <div class="rs-breadcrumbs img1">
                <div class="breadcrumbs-inner text-center">
                    <h1 class="page-title">UI/UX Development</h1>
                    <ul>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="/">Home</a>
                        </li>
                        <li title="Go To Services">
                            <a class="active" href="/ui-ux-design">Services</a>
                        </li>
                        <li>UI/UX Development</li>
                    </ul>
                </div>
            </div>

            {/* Breadcrumbs End  */}

            {/* About Section  */}

            <div class="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 pr-40 md-pr-15 md-mb-50">
                            <div class="sec-title4">
                                <span class="sub-text">UI/UX Development</span>
                                <h2 class="title">UI/UX Development</h2>
                                <div class="heading-line"></div>
                                <div class="desc desc-big">
                                    We at Econsign Soft work in dedicated project teams, with creative, intuitive, immersive UI UX designs.
                                </div>
                                <div class="desc">
                                   At Econsign Soft, we help our clients to create brilliant designs and superior user experiences through UX research, behavioral analytics and market insights. We explore innovative digital experience with a blend of technology, creativity and personalization in UI and UX development services. 
								   <ul class="listing-style pt-20">                              
									<li>
										<i class="fa fa-check-circle"></i>
										<span>Research</span>
									</li>
									<li>
										<i class="fa fa-check-circle"></i>
										<span>Storyboarding</span>
									</li>
									<li>
										<i class="fa fa-check-circle"></i>
										<span>Hybrid Model</span>
									</li>
									<li>
										<i class="fa fa-check-circle"></i>
										<span>Wireframes</span>
									</li>
									<li>
										<i class="fa fa-check-circle"></i>
										<span>Visual Design </span>
									</li>
									<li>
										<i class="fa fa-check-circle"></i>
										<span>Graphic Design </span>
									</li>
									<li>
										<i class="fa fa-check-circle"></i>
										<span>Branding</span>
									</li>
									<li>
										<i class="fa fa-check-circle"></i>
										<span>User Testing</span>
									</li>
								</ul>
                                </div>
                                <div class="btn-part mt-45">
                                    <a class="readon learn-more" href="/contact">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="software-img">
                                <img src="Images/about/about-us/1.jpg" alt="images"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rs-services style5 pt-120 md-pt-80">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 md-mb-30">
                                <div class="services-item">
                                    <div class="services-icon">
                                       <img src="Images/services/style7/1.png" alt="Images"/> 
                                    </div>
                                    <div class="services-content">
                                        <h3 class="services-title"><a href="/web-development">Expert Peoples</a></h3>
                                        <p class="services-desc">
                                             We have amassed significant expertise across a wide range of industries.	
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 md-mb-30">
                                <div class="services-item">
                                    <div class="services-icon">
                                       <img src="Images/services/style7/2.png" alt="Images"/> 
                                    </div>
                                    <div class="services-content">
                                        <h3 class="services-title"><a href="/web-development">Fast Growing Process</a></h3>
                                        <p class="services-desc">
                                            With our expertise and dedicated approach we help in growing our client business effectively.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="services-item">
                                    <div class="services-icon">
                                       <img src="Images/services/style7/3.png" alt="Images"/> 
                                    </div>
                                    <div class="services-content">
                                        <h3 class="services-title"><a href="/web-development">Creative Ideas</a></h3>
                                        <p class="services-desc">
                                            We have innovative and creative businees ideas which helps our client to be one step forward
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>

            <div id="rs-about" class="rs-about gray-color pt-120 pb-120 md-pt-80 md-pb-60">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 pr-45 md-pr-15 md-mb-50">
                            <div class="choose-img">
                               <img src="Images/choose/3.png" alt="images"/>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="sec-title mb-30">
                                <div class="sub-text choose">Why Choose Us</div>
                                <h2 class="title pb-20">
                                    We Are Increasing Business Success With Technology
                                </h2>
                                <div class="desc">
                                   Over 8 years working in IT services developing software applications and mobile apps for clients all over the world.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-10">
                                    <div class="rs-skillbar style1">
                                       <div class="cl-skill-bar">
                                    
                                       <SkillBar title="Full Stack Development" percent={92} />
                                        <SkillBar title="Software Development" percent={90} />
                                        <SkillBar title="App Development" percent={85} />
                                        <SkillBar title="Web Development" percent={95} />        

                                          <div class="btn-part mt-55">
                                              <a class="readon learn-more" href="contact.html">Learn-More</a>
                                          </div>
                                      </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}

        {/* Footer   */}
        <Footer/>

        {/* Scroll  */}

        <div id="scrollUp" class="orange-color">
            <i class="fa fa-angle-up"></i>
        </div>

        {/* Search Model  */}

        <div class="modal fade search-modal" id="searchModal" tabindex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
            <button type="button" class="close" data-bs-dismiss="modal">
                <span class="flaticon-cross"></span>
            </button>
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="search-block clearfix">
                        <form>
                            <div class="form-group">
                                <input class="form-control" placeholder="Search Here..." type="text"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>



        {/* // </body> */}
        </>
  )
}

export default UIUXDesign
