import React ,{ useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import SkillBar from './SkillBar';
import Navbars from "./Navbars";
import Footer from './Footer';



function Index() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
      });
      const [formErrors, setFormErrors] = useState({});
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
    
        // Basic validation: Check if required fields are not empty
        const errors = {};
        if (!formData.name) {
          errors.name = 'mame is required';
        }
        if (!formData.email) {
          errors.email = 'email is required';
        }
        if (!formData.phone) {
          errors.phone = 'phone number is required';
        }
        if (!formData.subject) {
          errors.subject = 'message is required';
        }
    
        if (Object.keys(errors).length === 0) {
          // Form is valid, you can submit it or perform further actions here
          console.log('Form submitted:', formData);
        } else {
          setFormErrors(errors);
        }
}

  return (
    <>
    <Navbars/>
    <body className="defult-home">
      <div className="main-content">
        <div className="full-width-header">
            {/* Banner Section  */}

            <div class="rs-banner main-home pt-100 pb-100  md-pt-80 md-pb-80" style={{
                background: `url('Images/banner/banner444.png')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'left top',
            }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-12 pr-140 md-mb-140 md-pr-15">
                            <div class="content-wrap">
                                <h1 class="it-title">IT Consulting Services For Your Business</h1>
                                <div class="description">
                                    <p class="desc">
                                        We are leading technology solutions providing company all over the world doing over 8 years.
                                    </p>
                                </div>
                                <div class="rs-videos">
                                    <div class="animate-border main-home">
                                        <a class="popup-border popup-videos" href="https://www.youtube.com/watch?v=uqZPq6mZhSI">
                                            <i class="fa fa-play"></i>
                                        </a>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12 pl-70 md-pl-15">
                            <div class="rs-contact">
                                <div class="contact-wrap">
                                   <div class="content-part mb-25">
                                       <h2 class="title mb-15">Get Quote</h2>
                                       <p class="desc">We here to help you with our experts</p>
                                   </div>
                                    <div id="form-messages"></div>
                                    <form id="contact-form" method="post" action="mailer.php">
                                        <fieldset>
                                            <div class="row">
                                                <div class="col-lg-12 mb-15">
                                                    <input class="from-control" type="text" id="name" name="name" placeholder="Name" required="" />
                                                    {formErrors.name && <p className="error-message" style={{ color: 'red' }}>{formErrors.name}</p>}
                                                </div> 
                                                <div class="col-lg-12 mb-15">
                                                    <input class="from-control" type="text" id="email" name="email" placeholder="E-Mail" required=""/>        
                                                    {formErrors.email && <p className="error-message" style={{ color: 'red' }}>{formErrors.email}</p>}
                                                </div>   
                                                <div class="col-lg-12 mb-15">
                                                    <input class="from-control" type="number" id="phone" name="phone" placeholder="Phone Number" required="" />  
                                                    {formErrors.phone && <p className="error-message" style={{ color: 'red' }}>{formErrors.phone}</p>}                                                  
                                                </div>   
                                                <div class="col-lg-12 mb-25">
                                                    <textarea class="from-control" type="text" id="Website" name="subject" placeholder="Message" required=""></textarea>
                                                    {formErrors.subject && <p className="error-message" style={{ color: 'red' }}>{formErrors.subject}</p>}
                                                </div>
                                            </div>
                                            <div class="form-group mb-0">
                                                <input class="submit-btn" type="submit" value="Submit Now" onClick={handleSubmit}/>
                                            </div>
                                       </fieldset>
                                    </form> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    {/* About Section */}

            <div id="rs-about" class="rs-about style1 pt-130 pb-190 md-pt-80 md-pb-80">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5 col-md-12 md-mb-50">
                            <div class="rs-animation-shape">
                                <div class="pattern">
                                   <img src="Images/about/main-home/about-part-1.png" alt=""/> 
                                </div>
                                <div class="middle">
                                   <img class="dance" src="Images/about/main-home/about-part-2.png" alt=""/> 
                                </div>
                                <div class="bottom-shape">
                                   <img class="dance2" src="Images/about/main-home/about-part-3.png" alt=""/> 
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12 pl-40 md-pl-15 md-pt-200 sm-pt-0">
                            <div class="sec-title mb-30">
                                <div class="sub-text">About Us</div>
                                <h2 class="title pb-20">
                                    We Are Increasing Business Success With Technology
                                </h2>
                                <div class="desc pr-90 md-pr-0">
                                   Over 8 years working in IT services developing software applications and mobile apps for clients all over the world.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-10">
                                    <div class="rs-skillbar style1">
                                       <div class="cl-skill-bar">

                                        <SkillBar title="Full Stack Development" percent={92} />
                                        <SkillBar title="Software Development" percent={90} />
                                        <SkillBar title="App Development" percent={85} />
                                        <SkillBar title="Web Development" percent={95} />

                                          <div class="btn-part mt-55">
                                              <a class="readon learn-more" href="/contact">Learn-More</a>
                                          </div>
                                      </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

{/* Services Section */}

            <div class="rs-services main-home gray-color pt-120 pb-120 md-pt-70 md-pb-70">
                <div class="container">
                    <div class="sec-title2 text-center mb-45">
                        <span class="sub-text">Services</span>
                        <h2 class="title">
                           We Are Offering All Kinds of IT Solutions Services
                        </h2>
                        <div class="heading-line"></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 mb-25">
                           <div class="services-item">
                               <div class="services-icon">
                                   <div class="image-part">
                                  
                         
                                       <img src="Images/services/main-home/icons/1.png" alt=""/> 
                                   </div>
                               </div>
                               <div class="services-content">
                                   <div class="services-text">
                                       <h3 class="services-title"><a href="/software-development">Software Development</a></h3>
                                   </div>
                                   <div class="services-desc">
                                       <p>
                                           Econsign Soft has proven expertise in building reliable and scalable software solutions that bring new value to your business.
                                       </p>
                                   </div>
                               </div>
                           </div> 
                        </div>
                        <div class="col-lg-4 col-md-6 mb-25">
                           <div class="services-item">
                               <div class="services-icon">
                                   <div class="image-part">
                                       <img src="/Images/services/main-home/icons/2.png" alt=""/> 
                                   </div>
                               </div>
                               <div class="services-content">
                                   <div class="services-text">
                                       <h3 class="services-title"><a href="/web-development">Web Development</a></h3>
                                   </div>
                                   <div class="services-desc">
                                       <p>
                                           We provide top-quality website development services for startups, enterprises & entrepreneurs.
                                       </p>
                                   </div>
                               </div>
                           </div> 
                        </div>
                        <div class="col-lg-4 col-md-6 mb-25">
                           <div class="services-item">
                               <div class="services-icon">
                                   <div class="image-part">
                                       <img src="Images/services/main-home/icons/3.png" alt=""/> 
                                   </div>
                               </div>
                               <div class="services-content">
                                   <div class="services-text">
                                       <h3 class="services-title"><a href="/full-stack-development">Full Stack Development</a></h3>
                                   </div>
                                   <div class="services-desc">
                                       <p>
                                          Leading full-stack web development firm that develops front-end and the back-end of web applications as per market standrad.
                                       </p>
                                   </div>
                               </div>
                           </div> 
                        </div>
                        <div class="col-lg-4 col-md-6 md-mb-25">
                           <div class="services-item">
                               <div class="services-icon">
                                   <div class="image-part">
                                       <img src="/Images/services/main-home/icons/4.png" alt=""/> 
                                   </div>
                               </div>
                               <div class="services-content">
                                   <div class="services-text">
                                       <h3 class="services-title"><a href="/app-development">App Development</a></h3>
                                   </div>
                                   <div class="services-desc">
                                       <p>
                                           We build a mobile app tailored to your niche under your budget that improve user experience & increase brand visibility.
                                       </p>
                                   </div>
                               </div>
                           </div> 
                        </div>
                        <div class="col-lg-4 col-md-6 sm-mb-25">
                           <div class="services-item">
                               <div class="services-icon">
                                   <div class="image-part">
                                       <img src="/Images/services/main-home/icons/5.png" alt=""/> 
                                   </div>
                               </div>
                               <div class="services-content">
                                   <div class="services-text">
                                       <h3 class="services-title"><a href="/ui-ux-design">UI/UX Design</a></h3>
                                   </div>
                                   <div class="services-desc">
                                       <p>
                                           We at Econsign Soft work dedicatedly to provide creative, intuitive, user research, ux design, ui and engineering. 
                                       </p>
                                   </div>
                               </div>
                           </div> 
                        </div>
                    </div>
                </div>
            </div>

            {/* Call Us Section Start  */}

            <div class="rs-call-us bg1 pt-120 md-pt-70 md-pb-80">
                <div class="container">
                    <div class="row rs-vertical-middle">
                        <div class="col-lg-6">
                            <div class="image-part">
                              <img src="/Images/call-us/contact-here.png" alt=""/>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="rs-contact-box text-center">
                                <div class="address-item mb-25">
                                    <div class="address-icon">
                                        <i class="fa fa-phone"></i>
                                    </div>
                                </div>
                                <div class="sec-title3">
                                    <span class="sub-text">CALL US</span>
                                    <h2 class="title">(+91) 20-67622438</h2>
                                    <p class="desc">Have any idea or project for in your mind call us or schedule a appointment. Our representative will reply you shortly.</p>
                                </div>
                                <div class="btn-part mt-40">
                                    <a class="readon lets-talk" href="contact">Let's Talk</a>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div> 
            </div>

            {/* Counter Section  */}

            <div class="rs-counter">
                 <div class="counter-top-area text-center bg2">
                     <div class="row">
                         <div class="col-md-4">
                             <div class="counter-list">
                                 <div class="counter-text">
                                     <div class="count-number">
                                         <span class="rs-count">30</span>
                                     </div>
                                     <h3 class="title">Happy Clients</h3>
                                 </div>
                             </div>
                         </div>
                         <div class="col-md-4 sm-mt-40 sm-mb-40">
                             <div class="counter-list">
                                 <div class="counter-text">
                                     <div class="count-number">
                                         <span class="rs-count plus">20</span>
                                     </div>
                                     <h3 class="title">Companies</h3>
                                 </div>
                             </div>
                         </div>
                         <div class="col-md-4">
                             <div class="counter-list">
                                 <div class="counter-text">
                                     <div class="count-number">
                                         <span class="rs-count plus">100</span>
                                     </div>
                                     <h3 class="title">Projects Done</h3>
                                 </div>
                             </div>
                         </div>
                     </div> 
                 </div>
            </div>

            {/* Process Section  */}

            <div class="rs-process pt-180 pb-120 md-pt-70 md-pb-70">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-4  md-mb-40">
                            <div class="process-wrap bg3">
                                <div class="sec-title mb-30">
                                    <div class="sub-text new">Working Process</div>
                                    <h2 class="title white-color">
                                        Our Working Process -  How We Work For Our Customers
                                    </h2>
                                </div>
                                <div class="btn-part mt-40">
                                    <a class="readon learn-more contact-us" href="/contact">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 pl-30 md-pl-15">
                            <div class="row">
                                <div class="col-md-6 mb-40">
                                    <div class="rs-addon-number">
                                        <div class="number-text">
                                            <div class="number-area">
                                                1.
                                            </div>
                                            <div class="number-title">
                                                <h3 class="title"> Discussion & Brainstorming</h3>
                                            </div>
                                            <p class="number-txt">  Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-40">
                                    <div class="rs-addon-number">
                                        <div class="number-text">
                                            <div class="number-area">
                                                2.
                                            </div>
                                            <div class="number-title">
                                                <h3 class="title">Planning & Prototyping</h3>
                                            </div>
                                            <p class="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 sm-mb-40">
                                    <div class="rs-addon-number">
                                        <div class="number-text">
                                            <div class="number-area">
                                                3.
                                            </div>
                                            <div class="number-title">
                                                <h3 class="title">Execution</h3>
                                            </div>
                                            <p class="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="rs-addon-number">
                                        <div class="number-text">
                                            <div class="number-area">
                                                4.
                                            </div>
                                            <div class="number-title">
                                                <h3 class="title">Delivery & Deployment</h3>
                                            </div>
                                            <p class="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Project Section  */}

            <div class="rs-project bg5 style1 pt-110 md-pt-80" style={{display:"none"}}>
                <div class="container">
                   <div class="sec-title2 text-center mb-45 md-mb-30">
                       <span class="sub-text white-color">Project</span>
                       <h2 class="title white-color">
                          We Are Offering All Kinds of IT Solutions Services
                       </h2>                       
                   </div>
                    <div class="rs-carousel owl-carousel" data-loop="true" data-items="3" data-margin="30" data-autoplay="true" data-hoverpause="true" data-autoplay-timeout="5000" data-smart-speed="800" data-dots="false" data-nav="false" data-nav-speed="false" data-center-mode="false" data-mobile-device="1" data-mobile-device-nav="false" data-mobile-device-dots="false" data-ipad-device="2" data-ipad-device-nav="false" data-ipad-device-dots="false" data-ipad-device2="2" data-ipad-device-nav2="false" data-ipad-device-dots2="false" data-md-device="3" data-md-device-nav="true" data-md-device-dots="false">
                        <div class="project-item">
                            <div class="project-img">
                                <a href="case-studies-single.html"><img src="Images/project/main-home/1.jpg" alt="images"/></a>
                            </div>
                            <div class="project-content">
                                <h3 class="title"><a href="case-studies-single.html">Product Design</a></h3>
                                <span class="category"><a href="case-studies-single.html">IT Technology</a></span>
                            </div>
                        </div>
                        <div class="project-item">
                            <div class="project-img">
                                <a href="case-studies-single.html"><img src="/Images/project/main-home/2.jpg" alt="images"/></a>
                            </div>
                            <div class="project-content">
                                <h3 class="title"><a href="case-studies-single.html">Product Engineering</a></h3>
                                <span class="category"><a href="case-studies-single.html">IT Technology</a></span>
                            </div>
                        </div>
                        <div class="project-item">
                            <div class="project-img">
                                <a href="case-studies-single.html"><img src="/Images/project/main-home/3.jpg" alt="images"/></a>
                            </div>
                            <div class="project-content">
                                <h3 class="title"><a href="case-studies-single.html">Analytic Solutions</a></h3>
                                <span class="category"><a href="case-studies-single.html">IT Technology</a></span>
                            </div>
                        </div>
                        <div class="project-item">
                            <div class="project-img">
                                <a href="case-studies-style1"><img src="/Images/project/main-home/4.jpg" alt="images"/></a>
                            </div>
                            <div class="project-content">
                                <h3 class="title"><a href="case-studies-single.html">Growth Strategies</a></h3>
                                <span class="category"><a href="case-studies-single.html">IT Technology</a></span>
                            </div>
                        </div>
                        <div class="project-item">
                            <div class="project-img">
                                <a href="case-studies-single.html"><img src="/Images/project/main-home/5.jpg" alt="images"/></a>
                            </div>
                            <div class="project-content">
                                <h3 class="title"><a href="case-studies-single.html">Platform Integration</a></h3>
                                <span class="category"><a href="case-studies-single.html">IT Technology</a></span>
                            </div>
                        </div>
                        <div class="project-item">
                            <div class="project-img">
                                <a href="case-studies-style1"><img src="/Images/project/main-home/6.jpg" alt="images"/></a>
                            </div>
                            <div class="project-content">
                                <h3 class="title"><a href="case-studies-single.html">Innovative Interfaces</a></h3>
                                <span class="category"><a href="case-studies-single.html">IT Technology</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Partner Start  */}

            <div class="rs-partner pt-80 pb-70" style={{display:"none"}}>
                <div class="container">
                    <div class="rs-carousel owl-carousel" data-loop="true" data-items="5" data-margin="30" data-autoplay="true" data-hoverpause="true" data-autoplay-timeout="5000" data-smart-speed="800" data-dots="false" data-nav="false" data-nav-speed="false" data-center-mode="false" data-mobile-device="2" data-mobile-device-nav="false" data-mobile-device-dots="false" data-ipad-device="3" data-ipad-device-nav="false" data-ipad-device-dots="false" data-ipad-device2="2" data-ipad-device-nav2="false" data-ipad-device-dots2="false" data-md-device="5" data-md-device-nav="false" data-md-device-dots="false">
                        <div class="partner-item">
                            <div class="logo-img">
                                <a href="https://rstheme.com">
                                    <img class="hover-logo" src="/Images/partner/1.png" alt=""/>
                                    <img class="main-logo" src="/Images/partner/1.png" alt=""/>
                                </a>
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="logo-img">
                                <a href="https://rstheme.com">
                                    <img class="hover-logo" src="/Images/partner/2.png" alt=""/>
                                    <img class="main-logo" src="/Images/partner/2.png" alt=""/>
                                </a>
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="logo-img">
                                <a href="https://rstheme.com">
                                    <img class="hover-logo" src="/Images/partner/3.png" alt=""/>
                                    <img class="main-logo" src="/Images/partner/3.png" alt=""/>
                                </a>
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="logo-img">
                                <a href="https://rstheme.com">
                                    <img class="hover-logo" src="/Images/partner/4.png" alt=""/>
                                    <img class="main-logo" src="/Images/partner/4.png" alt=""/>
                                </a>
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="logo-img">
                                <a href="https://rstheme.com">
                                    <img class="hover-logo" src="/Images/partner/5.png" alt=""/>
                                    <img class="main-logo" src="/Images/partner/5.png" alt=""/>
                                </a>
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="logo-img">
                                <a href="https://rstheme.com">
                                    <img class="hover-logo" src="/Images/partner/6.png" alt=""/>
                                    <img class="main-logo" src="/Images/partner/6.png" alt=""/>
                                </a>
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="logo-img">
                                <a href="https://rstheme.com">
                                    <img class="hover-logo" src="Images/partner/7.png" alt=""/>
                                    <img class="main-logo" src="Images/partner/7.png" alt=""/>
                                </a>
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="logo-img">
                                <a href="https://rstheme.com">
                                    <img class="hover-logo" src="Images/partner/8.png" alt=""/>
                                    <img class="main-logo" src="Images/partner/8.png" alt=""/>
                                </a>
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="logo-img">
                                <a href="https://rstheme.com">
                                    <img class="hover-logo" src="Images/partner/9.png" alt=""/>
                                    <img class="main-logo" src="Images/partner/9.png" alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        

        {/* Footer Start  */}
        <Footer/>


{/* Start Scroll Up  */}

        <div id="scrollUp" class="orange-color">
            <i class="fa fa-angle-up"></i>
        </div>

{/* Scoll End  */}

<div class="modal fade search-modal" id="searchModal" tabindex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
            <button type="button" class="close" data-bs-dismiss="modal">
                <span class="flaticon-cross"></span>
            </button>
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="search-block clearfix">
                        <form>
                            <div class="form-group">
                                <input class="form-control" placeholder="Search Here..." type="text"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     </div>
       </div>
     </body>
    </>    
  );
}

export default Index;
